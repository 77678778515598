import { Component } from '@angular/core';

@Component({
  selector: 'app-404',
  templateUrl: './404.component.html',
  styleUrl: './404.component.scss'
})
export class NotFoundComponent {

}
