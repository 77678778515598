import { Component } from '@angular/core';

@Component({
  selector: 'app-denied',
  templateUrl: './denied.component.html',
  styleUrl: './denied.component.scss'
})
export class DeniedComponent {

}
