import { Injectable, OnDestroy } from '@angular/core';
import { IUser } from '../models/interface/IUser';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppDataService implements OnDestroy {
  private user: IUser = null;

  private _destroyed$ = new Subject();

  constructor(
    private _router: Router,
  ) { }

  ngOnDestroy(): void {
    this._destroyed$.next('');
  }

  /**
   * The current user's information
   */
  get User(): IUser {
    return this.user
  }

  set User(user: IUser) {
    this.user = user
  }

  get Token(): string {
    return localStorage['token'] as string;
  }

  set Token(token: string) {
    localStorage['token'] = token;
  }

  logOut(): void {
    localStorage.removeItem('token');
    this.User = null;

    void this._router.navigate(['login']);
  }

  clearSessionData(): void {
    sessionStorage.clear()
  }

  /**
   * Get and set a sites public ID
   */
  get client_id(): string {
    return sessionStorage.getItem('client_id') as string
  }

  set client_id(client_id: string) {
    sessionStorage.setItem('client_id', client_id)
  }

  get redirect_url(): string {
    return sessionStorage.getItem('redirect_url') as string
  }

  set redirect_url(redirect_url: string) {
    sessionStorage.setItem('redirect_url', redirect_url)
  }

  get prompt(): string {
    return sessionStorage.getItem('prompt') as string
  }

  set prompt(prompt: string) {
    sessionStorage.setItem('prompt', prompt)
  }

  get code_challenge(): string {
    return sessionStorage.getItem('code_challenge') as string
  }

  set code_challenge(code_challenge: string) {
    sessionStorage.setItem('code_challenge', code_challenge)
  }

  get code_challenge_method(): string {
    return sessionStorage.getItem('code_challenge_method') as string
  }

  set code_challenge_method(code_challenge_method: string) {
    sessionStorage.setItem('code_challenge_method', code_challenge_method)
  }

  get state(): string {
    return sessionStorage.getItem('state') as string
  }

  set state(state: string) {
    sessionStorage.setItem('state', state)
  }
}
